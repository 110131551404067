@mixin background($url) {
  background-image: url($url);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@mixin size($width, $height) {
  display: inline-block;
  position: relative;
  width: $width;
  height: $height;
}
@mixin icon($width, $height) {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  width: $width;
  height: $height;
  cursor: pointer;
}

.icon {
  &-arrow-down {
    color: #505050;
    @include size(15px, 10px);
    &::after {
      @include icon(15px, 10px);
      @include background('../icons/arrow-down.svg');
    }
  }
  &-arrow-up {
    @include size(15px, 10px);
    color: #505050;
    &::after {
      @include icon(15px, 10px);
      @include background('../icons/arrow-up.svg');
      transform: translateX(180deg);
    }
  }

  &-add-plus {
    @include size(32px, 34px);
    &::after {
      @include icon(32px, 34px);
      @include background('../icons/plus.svg');
    }
  }
  &-add-plus-disable {
    @include size(32px, 34px);
    &::after {
      @include icon(32px, 34px);
      @include background('../icons/plus-disable.svg');
    }
  }

  &-valid {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/btn-valid.svg');
    }
  }
  &-valid-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/btn-valid-disable.svg');
    }
  }

  &-delete {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-delete.svg');
    }
  }

  &-delete-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-delete-disable.svg');
      cursor: not-allowed;
    }
  }

  &-cross-delete {
    @include size(13px, 12px);
    &::after {
      @include icon(13px, 12px);
      @include background('../icons/cross-delete.svg');
    }
  }

  &-edit {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/edit.svg');
    }
  }

  &-edit-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/edit-disable.svg');
      cursor: not-allowed;
    }
  }

  &-edit-circle {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-edit.svg');
    }
  }

  &-edit-circle-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-edit-disable.svg');
    }
  }

  &-cancel {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/btn-delete.svg');
    }
  }

  &-cancel-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/btn-delete-disabled.svg');
    }
  }

  &-cancel-circle {
    @include size(28px, 27px);
    &::after {
      @include icon(28px, 27px);
      @include background('../icons/delete-circle.svg');
    }
  }

  &-save {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-save.svg');
    }
  }

  &-save-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/icon-save-disable.svg');
    }
  }

  &-test {
    @include size(70px, 26px);
    &::after {
      @include icon(70px, 26px);
      @include background('../icons/icon-test.svg');
    }
  }

  &-test-disable {
    @include size(70px, 26px);
    &::after {
      @include icon(70px, 26px);
      @include background('../icons/icon-test-disable.svg');
    }
  }

  &-sync {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/sync.svg');
    }
  }

  &-sync-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/sync-disable.svg');
    }
  }

  &-refresh {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/refresh-active.svg');
    }
  }

  &-refresh-disable {
    @include size(27px, 27px);
    &::after {
      @include icon(27px, 27px);
      @include background('../icons/refresh-disable.svg');
    }
  }

  &-auto {
    @include size(27px, 27px);
    &::after {
      cursor: not-allowed;
      @include icon(27px, 27px);
      @include background('../icons/auto.svg');
    }
  }
  &-close-modal {
    @include size(20px, 20px);
    &::after {
      cursor: not-allowed;
      @include icon(20px, 20px);
      @include background('../icons/close-modal.svg');
    }
  }

  &-search {
    @include size(25px, 25pxx);
    &::after {
      cursor: not-allowed;
      @include icon(25px, 25px);
      @include background('../icons/search.svg');
    }
  }

  &-clear-search {
    @include size(25px, 25pxx);

    &::after {
      cursor: not-allowed;
      @include icon(25px, 25px);
      @include background('../icons/close.svg');
    }
  }

  &-arrow-right {
    @include size(24px, 24px);
    &::after {
      cursor: not-allowed;
      @include icon(24px, 24px);
      @include background('../icons/arrow-right.svg');
    }
  }
}
